<template>

<button class="button icon-hover-button mb-1" v-on:click="Redirect()">
  <div :href=link class="dub-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
  <path :d=svg />
</svg></div>
  <div class="text text-content" :href=link><strong>{{ text }}</strong></div>
</button>
</template>

<script>
export default {
    name: 'IconHoverButton',
    props: {
    link:String,
    svg:String,
    text:String
    },
    methods:{
      Redirect(){
        if(this.link != ''){
           window.open(this.link, '_blank');
        }
       
      }
    }
}
</script>
<style scoped>
svg{
    fill:white;
}
h1 {
  font-size: 1.5em;
  text-align: center;
  padding: 70px 0 0 0;
  color: #EF8354;
  font-weight: 300;
  letter-spacing: 1px;
}

span {
  border: 2px solid #4F5D75;
  padding: 10px;
}


/* Layout Styling */

/* Button Styles */

.button {
  background-color: black;
  height: 40px;
  width: 150px;
  z-index: 10;
  border: 2px solid white;

  color: #BFC0C0;
  text-transform: uppercase;
  text-decoration: none;
  font-size: .8em;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.text {
  color: #87CEEB;
  text-decoration: none;
  letter-spacing: 1px;
}
.icon-hover-button{
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.icon-hover-button .text {
  position: relative;
  left: 0;
  transition: all .35s ease-Out;
}

.dub-arrow {
  width: 100%;
  height: 100%;
  background: #87CEEB;
  left: -200px;
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .35s ease-Out;
  bottom: 0;
}

.icon-hover-button img {
  width: 20px;
  height: auto;
}

.icon-hover-button:hover .dub-arrow {
  left: 0;
}

.icon-hover-button:hover .text {
  left: 150px;
}
</style>
<template>
	<div id="skill" class="section">
    <h1 class="text-content">{{ this.Heading }}</h1>
    <h4 style="text-align: left; margin-bottom: 30px;" class="text-content"> {{ this.Subheading }}</h4>
    <div class="row row-cols-lg-6 row-cols-md-3 row-cols-2 justify-content-lg-between align-items-start">
      <div v-for="skill in skills" :key="skill.name" class="card card-responsive mb-3">
        <SkillComponent :skill="skill"/>
      </div>
    </div>
  </div>  
</template>
<script>
import SkillComponent from './SkillComponent.vue';
import { ref } from 'vue';
export default {
    name: 'Skills',
    computed:{
      Heading(){
        return this.$t("Skills")
      },
      Subheading(){
        return this.$t("SkillSubheading")
      }
    },
    setup() {
        const skills = ref([
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg", name: "Python"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg", name: "HTML"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg", name: "CSS"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg", name: "Javascript"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg", name: "PHP"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg", name: "Bootstrap"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg", name: "Vue.js"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg", name: "Flask"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/unity/unity-original.svg", name: "Unity"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg", name: "C#"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg", name: "C++"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg", name: "Flutter"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg", name: "Firebase"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg", name: "MySQL"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/selenium/selenium-original.svg", name: "Selenium"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tensorflow/tensorflow-original.svg", name: "Tensorflow"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pandas/pandas-original.svg", name: "Pandas"},
            { image_link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/opencv/opencv-original.svg", name: "Opencv"},
        ]);
        return { skills };
    },
    components: { SkillComponent }
}
</script>
<style scoped>
h1{
    color:rgba(0,0,128);
    text-align: left;
}

.card-responsive{
  border-radius: 4px;
	z-index: 10;
  background: rgba(0,0,0,0);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.card-responsive:hover{
  transform: scale(1.05);
}
</style>
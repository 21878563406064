<template>
<div class="row px-xl-0 px-1">
<div class="mb-4 col-xl-6 col-12">
    <h3 class="text-content mx-2">{{ this.ProjectName }}</h3>
    <div v-for = "(n,i) in Math.ceil(this.project.tech_stack.length/6)" :key="i" class="row justify-content-sm-center justify-content-around"> 
      <i v-for ="tech in this.project.tech_stack.slice(6*i,6*i+6)" :key="tech" :class="[tech,'col-1','px-0','mx-1']"></i>
    </div>
    <p  class="text-content mx-2">{{this.ProjectDescription}}</p>
    <div class="row row-cols-2 justify-content-around align-items-around mx-2">
        <div class="col-xl-1 d-none d-xl-flex "></div>
        <IconHoverButton svg="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" :link=project.link_to_site :text=this.Visit></IconHoverButton>
        <IconHoverButton svg="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" :text=this.GitHubRepo></IconHoverButton>
        <div class="col-xl-1 d-none d-xl-flex"></div>
    </div>

</div>
<a class="col-12 col-xl-6 d-flex" :href=project.link_to_site target="_blank">
    <div class="card h-100 card-responsive">
        <div class="card-body m-0 p-0 d-flex ">
            <img :src="require('@/assets/' + project.image_link + '')" class="img-fluid " alt="A cool pic"/>
        </div>
    </div>
</a>
</div>
</template>
<script>
import IconHoverButton from './IconHoverButton.vue';
export default {
    name: 'Project',
    components:{IconHoverButton},
    props:["project"],
    computed:{
      Visit(){
        return this.$t("VisitLabel");
      },
      GitHubRepo(){
        return this.$t("GitHubLabel");
      },
      ProjectName(){
        return this.$t(this.project.id+"-Name");
      },
      ProjectDescription(){
        return this.$t(this.project.id+"-Description");
      },
    }
   
}
</script>
<style scoped>
i{
    margin-left: 10px;
    font-size: 35px;
}
.card-responsive{
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12);
}

.card-responsive:hover{
  transform: scale(1.05);
}
</style>
<template>
  <NavigationBar></NavigationBar>
  <LanguageFloatingOptions></LanguageFloatingOptions>
  <Hero/>
  <About></About>
  <Skills></Skills>
  <Projects></Projects>
  <Contact></Contact>
  <Footer></Footer>
</template>
<script>
import Hero from './components/Hero.vue';
import LanguageFloatingOptions from './components/LanguageFloatingOptions.vue';
import NavigationBar from './components/NavigationBar.vue';
import Footer from './components/Footer.vue';
import About from './components/About.vue';
import Skills from './components/Skills.vue';
import Contact from './components/Contact.vue';
import Projects from './components/Projects.vue'
export default {
  name: 'App',
  components: {
    NavigationBar,
    Hero,
    LanguageFloatingOptions,
    About,
    Skills,
    Projects,
    Contact,
    Footer
  }
}
</script>

<style>
#app {
  font-family: GT Walsheim Pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
  
}
img{
  height: 400px;
}
</style>

<template>
	<div id="projects" class="section">
    <h1 class="text-content">{{ this.Heading }}</h1>
    <h4 style="text-align: left; margin-bottom: 10px;" class="text-content">{{ this.Subheading }}</h4>
    <ProjectCarousel v-for="projects in all_projects" :key="projects.name" :name=projects.name :projects=projects.projects></ProjectCarousel>
    <!-- <div class="row row-cols-lg-2 row-cols-1 justify-content-lg-between align-items-start ">
        <Project v-for="project in web_projects" :key="project.name" :project=project></Project>
    </div> -->
    
  </div>  
</template>
<script>
import { ref } from 'vue';
import ProjectCarousel from './ProjectCarousel.vue';
import Project from './Project.vue'
export default {
    name: 'Projects',
    computed:{
      Heading(){
        return this.$t("Projects")
      },
      Subheading(){
        return this.$t("ProjectSubheading")
      },
     
     
    },
    setup() {
        const web_projects = ref([
            { image_link: "protomanga.png", id: "ProtoManga",link_to_site:"http://protomanga.pythonanywhere.com/",tech_stack:["devicon-python-plain-wordmark colored","devicon-html5-plain-wordmark colored" ,"devicon-css3-plain-wordmark colored","devicon-bootstrap-plain-wordmark colored","devicon-flask-original-wordmark","devicon-mysql-plain-wordmark colored","devicon-mysql-plain-wordmark colored"]},
            { image_link: "protomanga.png", id: "ProtoManga",link_to_site:"http://protomanga.pythonanywhere.com/",tech_stack:["devicon-python-plain-wordmark colored","devicon-html5-plain-wordmark colored","devicon-css3-plain-wordmark colored","devicon-bootstrap-plain-wordmark colored","devicon-flask-original-wordmark","devicon-mysql-plain-wordmark colored"]},
        ]);
        const game_projects = ref([
            { image_link: "protomanga.png", id: "ProtoManga",link_to_site:"http://protomanga.pythonanywhere.com/",tech_stack:["devicon-python-plain-wordmark","devicon-html5-plain-wordmark","devicon-css3-plain-wordmark","devicon-bootstrap-plain-wordmark","devicon-flask-original-wordmark","devicon-mysql-plain-wordmark"]},
        ]);
        const application_projects = ref([
            { image_link: "protomanga.png", id: "ProtoManga",link_to_site:"http://protomanga.pythonanywhere.com/",tech_stack:["devicon-python-plain-wordmark","devicon-html5-plain-wordmark","devicon-css3-plain-wordmark","devicon-bootstrap-plain-wordmark","devicon-flask-original-wordmark","devicon-mysql-plain-wordmark"]},
        ]);
        const all_projects = ref([
          {projects:web_projects,name:"Website Projects"},
          {projects:game_projects,name:"Game Projects"},
          {projects:application_projects,name:"Application Projects"},
        ])
        return { all_projects,web_projects };
    },
    components:{ProjectCarousel,Project}
}
</script>
<style scoped>
h1{
    color:rgba(0,0,128);
    text-align: left;
}

.card-responsive{
	z-index: 10;
  background: rgba(0,0,0,0);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.card-responsive:hover{
  transform: scale(1.05);
}

</style>
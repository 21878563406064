import { createApp } from 'vue'
import App from './App.vue'
import US from './locale/us.json'
import JP from './locale/jp.json'
import './assets/css/style.css'
import { createI18n } from 'vue-i18n'
var language = document.cookie.split("=")[1];
if(language == null){
    language = "US";
    document.cookie = 'locale=US';
}
console.log(language);
const i18n = createI18n({
    locale:language,
    messages:{
        US:US,
        JP:JP
    }
})
createApp(App).use(i18n).mount('#app')

<template>
  <div class = "language-floating-container">
    <div class = "language-floating-content">

      <i class="flag flag-us"  v-on:click="changeToEnglish()"></i>
      <i class="flag flag-jp"  v-on:click="changeToJapanese()"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LanguageFloatingOptions',
  mounted:function mounted(){
      if(document.cookie.split("=")[1] == "US"){
        
        document.getElementsByClassName("flag-us")[0].classList.add("faded");
      }
      else{
        document.getElementsByClassName("flag-jp")[0].classList.add("faded");
      }
  },
  methods:{
    changeToEnglish: function() {
				
        document.getElementsByClassName("flag-us")[0].classList.add("faded");
        this.triggerTextFade();
        setTimeout(() => {
          document.cookie='locale=US';
				  this.$i18n.locale= 'US';
          document.getElementsByClassName("flag-jp")[0].classList.remove("faded");
        }, 400);
      },
      changeToJapanese: function() {
        document.getElementsByClassName("flag-jp")[0].classList.add("faded");
        this.triggerTextFade();
        setTimeout(() => {
          document.cookie='locale=JP';
				  this.$i18n.locale= 'JP';
          document.getElementsByClassName("flag-us")[0].classList.remove("faded");
        }, 400);
      },
      triggerTextFade: function(){
        var classes_to_check = ["text-content",'visible']
        for(var j = 0; j < classes_to_check.length;j++){
          console.log(classes_to_check[j]);
          var all_txt = document.getElementsByClassName(classes_to_check[j]);
          for(var i=0; i < all_txt.length;i++){
            all_txt[i].classList.remove("textFade");
            void all_txt[i].offsetWidth;
            all_txt[i].classList.add("textFade");
            setTimeout(function(){
              $(".textFade").removeClass("textFade");
            },1000);
          }
        }
        
      }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.language-floating-container{
        position: fixed;
        height: 20px;
        width:20px;
        right:0px;
        bottom:-20px;
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        z-index: 2;
    }
.language-floating-content{
    width:50px;
    height:50px;
    position: absolute;
    overflow-x:hidden;
    z-index: 1;

}
.faded{
  opacity: 0.5;
  transition: all 0.5s linear;
}
.selected{
  opacity: 1;
  transition: all 0.5s linear;
}
i{
  z-index: 3;
  position: relative;
  cursor: pointer;
}
</style>

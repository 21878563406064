<template>
<div id="contact" class="section">
<h1 class="text-content">{{ this.Heading }}</h1>
<h4 style="text-align: left; margin-bottom: 10px;" class="text-content"> {{ this.Subheading1 }}</h4>
<h4 style="text-align: left; margin-bottom: 10px;" class="text-content"> {{ this.Subheading2 }}</h4>
</div>
<div id="contact-form-container" >
    <form action ='' method = 'get' id='contact-form' class='mt-4 col-lg-9 col-12' v-on:submit.prevent="submitForm">
        <div class="form-floating mb-4">
            <input type="text" class="form-control" id="Name"  name="Name" placeholder="Wooper" required autocomplete="off">
            <label for="Name" class="text-content">{{ this.NameLabel }}</label>
        </div>
        <div class="form-floating mb-4">
            <input type="email" class="form-control" id="Email" name="Email" placeholder="name@example.com" required autocomplete="off">
            <label for="Email" class="text-content">{{ this.EmailLabel }}</label>
        </div>
        
        <div class="form-floating mb-4">
            <textarea class="form-control" id="Message" name="Message" placeholder="Acquire the Sire" required autocomplete="off"></textarea>
            <label for="Message" class="text-content">{{ this.MessageLabel }}</label>
        </div>
        <IconHoverButton svg="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" link="" :text=this.SendLabel></IconHoverButton>
    </form>
</div>
    
</template>

<script>
import IconHoverButton from './IconHoverButton.vue';

export default {
    name: 'Contact',
    components:{IconHoverButton},
    computed:{
      Heading(){
        return this.$t("Contact")
      },
      Subheading1(){
        return this.$t("ContactSubheading1")
      },
      Subheading2(){
        return this.$t("ContactSubheading2")
      },
      NameLabel(){
        return this.$t("NameLabel")
      },
      EmailLabel(){
        return this.$t("EmailLabel")
      },
      MessageLabel(){
        return this.$t("MessageLabel")
      },
      SendLabel(){
        return this.$t("SendLabel")
      },
      Reponse(){
        return this.$t("Reponse")
      }
    },
    methods:{
        submitForm:function(){
            $.post('https://mtk8.pythonanywhere.com/send_info', $('#contact-form').serialize());
            alert(this.Reponse);
            $('#contact-form')[0].reset();
        },
    },
}
</script>
<style scoped>
h1{
    color:rgba(0,0,128);
    text-align: left;
}
#contact{
  z-index: 0 ;
  position: relative; 
}
#contact-form-container{
margin-left: 15%;
margin-right: 15%;
margin-bottom: 100px;
z-index: 20 ;
position: relative; 
}
input,textarea{
    background-color: black;
    border: none;
    z-index: 20;
    border: 2px solid white;
    border-radius: 5px;
    color:white;
}
input:focus,textarea:focus {
    background-color: black;
    border: none;
    z-index: 20;
    border: 2px solid #87CEEB;
    border-radius: 5px;
    color:white;
}
input + label,textarea + label{
    color:#87CEEB !important ;
    font-size: 17px;
    opacity: 1 ;
}
input:focus + label,textarea:focus + label{
    color:white !important;
    opacity: 1 !important;
}
textarea {
    min-height: 140px; 
}
</style>
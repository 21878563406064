<template>
  <nav class="navbar navbar-expand flex-column my-0 py-0 fixed-top">
    <div class="container">
      <a class="navbar-brand w-100 text-content mx-0" href="#">{{ this.Name }}</a>
    </div>
    <div class="w-100 ">
      <div class="collapse navbar-collapse " id="navbarNav">
        <ul class="navbar-nav text-center mx-auto w-100 justify-content-around" style="height: 40px;">
         <li class="nav-item col-3 h-100">
            <a class="nav-link text-content text-responsive" href="#about">{{ this.About }}</a>
          </li>
          <li class="nav-item col-3 h-100">
            <a class="nav-link text-content text-responsive" href="#skill">{{this.Skills}}</a>
          </li>
          <li class="nav-item col-3 text-responsive h-100">
            <a class="nav-link text-content text-responsive" href="#projects">{{ this.Projects }}</a>
          </li>
          <li class="nav-item col-3 text-responsive h-100">
            <a class="nav-link text-content text-responsive" href="#contact">{{this.Contact}}</a>
          </li>
        </ul>
      </div>
  </div>
</nav>
</template>
<script>
export default {
  name:'NavigationBar',
  computed:{
    Name(){
      return this.$t("Name");
    },
    About(){
      return this.$t("About");
    },
    Skills(){
      return this.$t("Skills");
    },
    Projects(){
      return this.$t("Projects");
    },
    Contact(){
      return this.$t("Contact");
    },
  }
}
</script>
<style scoped>
.navbar-brand{
  color:white !important;
  display: inline;
  text-align: center;
  width: 100%;
}
#navbarNav{
  width: 100%;
}
.nav-link:hover{
  color: rgba(0,0,128) !important;
  background-color:white !important;
  transition: all 0.5s ease;
}

.nav-link{
  color:white !important;
}
.navbar-toggler-icon{
  color: white !important;
}
.navbar{
    background-color: rgba(0,0,128,0.5);
}
</style>
<template>
  <img v-if="skill.name == 'Unity'" class="card-img-top  fliter-white" :src=skill.image_link />
  <img v-else-if="skill.name == 'Flask'" class="card-img-top  fliter-white" :src=skill.image_link />
  <img v-else  class="card-img-top" :src=skill.image_link>
  <div class="card-body m-0 p-0 d-flex flex-column">
  <h5 class="card-title">{{skill.name}}</h5>
  </div>
 
</template>
<script>
export default {
    name: 'SkillComponent',
    props:["skill"],
}
</script>
<style scoped>


img{
  height: 150px;
}
.fliter-white{
  filter: invert(90%) sepia(100%) saturate(1%) hue-rotate(236deg) brightness(104%) contrast(101%);
}
</style>
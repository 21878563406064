<template>
<h4 class="w-100 align-items-center">{{ name }}</h4>
<div :id=this.IdForCaraousel(name) class="carousel slide align-items-center " data-bs-interval="false" data-ride="carousel" data-pause="hover">
  <div class="carousel-indicators">
    <button type="button" :data-bs-target=this.IdForCaraouselIndicators(name) :data-bs-slide-to=this.GetIndex() class="active" aria-current="true"></button>
    <button type="button" v-for="project in projects.slice(1)" :key="project.name" :data-bs-target=this.IdForCaraouselIndicators(name) :data-bs-slide-to=this.GetIndex()></button>
    <!-- <button type="button" :data-bs-target=this.IdForCaraouselIndicators(name) data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" :data-bs-target=this.IdForCaraouselIndicators(name) data-bs-slide-to="2" aria-label="Slide 3"></button> -->
  </div>

  <div class="carousel-inner p-3">
    <div class="carousel-item active">
      <Project :project=projects[0]></Project>
    </div>
    <div class="carousel-item" v-for="project in projects.slice(1)" :key="project.name">
      <Project :project=project></Project>
    </div>
  </div>
</div>
</template>
<script>
import { ref } from 'vue';
import Project from './Project.vue';

export default {
    name: 'ProjectCarousel',
    components:{Project},
    props:["projects","name"],
    
    methods:{
        IdForCaraouselIndicators(name){
            return "#"+name.split(" ")[0];
        },
        IdForCaraousel(name){
            return name.split(" ")[0];
        },
        GetIndex(){
            var res = this.counter;
            this.counter+=1;
            return ""+res;
        }
    },
    setup(){
        var counter = 0;
        return {counter};
    },
}
</script>
<style scoped>
.carousel-indicators {
        bottom: -1rem;
}
.carousel-indicators li {
    background-color: #ccc;
}
h4{
    color: #87CEEB ;
    margin-top: 40px;
}
.carousel{
    text-align: center;
    padding-bottom: 20px;
}
</style>
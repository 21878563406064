<template>
<!-- Footer -->

<!-- Section: Social media -->
<footer class=" text-center text-lg-start d-flex justify-content-between pb-4">
  <!-- Left -->
  <div class="mx-2">
    <span class="text-content">© 2023, Built and designed by<br>{{ this.Name }}</span>
  </div>
  <!-- Left -->

  <!-- Right -->
  <div class="social-media">
    <div>
      <span class="text-content">{{ this.Social }}:</span>
    </div>
    <a href="https://www.facebook.com/profile.php?id=100082809391282" class="me-4 text-reset">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="https://twitter.com/MoeTheinKyaw" class="me-4 text-reset">
      <i class="fab fa-twitter"></i>
    </a>
    <a href="https://mail.google.com/mail/?view=cm&to=mtkyaw2004@gmail.com" class="me-4 text-reset">
      <i class="fab fa-google"></i>
    </a>
    <a href="https://www.linkedin.com/in/moe-thein-kyaw-738b75246/?originalSubdomain=mm" class="me-4 text-reset">
      <i class="fab fa-linkedin"></i>
    </a>
    <a href="https://github.com/Protype8" class="me-4 text-reset">
      <i class="fab fa-github"></i>
    </a>
  </div>
  <!-- Right -->
</footer>
  <!-- Section: Social media -->

</template>

<script>
export default {
  name: 'Footer',
  computed:{
    Name(){
      return this.$t("Name");
    },
    Social(){
      return this.$t("Social");
    },
  }
}
</script>

<style scoped>
footer{
  background-color: rgba(0,0,128,0.5);
  z-index: 1;
  position: relative;
}
a{
  color:#ffffff;
  transition: all 0.25s linear;
}
a:hover{
  color:#87CEEB !important;
  transition: all 0.25s linear;
}
.social-media{
  z-index: 0;
}
</style>
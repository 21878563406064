<template>
  <div id= "matrix_rain" class="mx-0 px-0 w-100">
    <canvas id="canvas"></canvas>
    <div id="Intro1" class= "w-100 hidden-left">{{ this.Hello }}</div>
    <div id="Fact1-left" class= "fact w-100 hidden">{{ this.$t("Fact1") }}</div>
    <div v-for="fact in facts.slice(1)" :key="fact" :id=this.FactLeftId(fact) class="fact w-100 hidden-left">{{ this.$t(fact) }}</div>
    <div v-for="fact in facts" :key="fact" :id=this.FactRightId(fact) class="fact w-100 hidden-right">{{ this.$t(fact) }}</div>
    <div id="Facts1" class= "w-100 hidden"></div>
    <div id="Facts2" class= "w-100 hidden-right"></div>
    <div id="Intro2" class= "w-100 hidden-right">{{ this.Name }}</div>
  </div>
</template>

<script>
var fact_amount = 5;
var canvas,context;
var W = screen.width;
var factIndex = 0;
var H = screen.height;
var fontSize = 16;
var columns = Math.ceil(W / fontSize);
var drops = [];
var loaded = false;
var loadingInterval;
var current_dir = "right";
for(var i=0; i<columns; i++){
    drops.push(0);
}
export default {
  name: 'Hero',
  computed:{
    Alphabets(){
      return this.$t("Alphabets");
    },
    Name(){
      return this.$t("HeroName");
    },
    Hello(){
      return this.$t("Hello");
    },
    FactLeftId(){
      return fact=> fact+"-left"; 
    },
    FactRightId(){
      return fact=> fact+"-right"; 
    }

  },
  mounted: function mounted(){
    canvas = document.getElementById('canvas');
    context = canvas.getContext('2d');
    canvas.width = W;
    canvas.height = H;
    loadingInterval = setInterval(this.draw, 30);
  },
  setup(){
    const facts=[]
    for(var i = 0;i < fact_amount;i++){
      facts.push("Fact"+(i+1));
    }
    return { facts };
  },
  methods:{
    draw(){
      var str = this.Alphabets;
      context.fillStyle = "rgba(0,0,0,0.05)";
      context.fillRect(0, 0, W, H);
      context.fontSize = "700 " + fontSize + "px";
      context.fillStyle = "#87CEEB";
      for(var i=0; i<columns; i++){
        var index = Math.floor(Math.random()*str.length);
        var x = i * fontSize;
        var y = drops[i] * fontSize;
        context.fillText(str[index], x, y);
        if(y >= canvas.height && Math.random() > 0.99){
            if(!loaded){
              loaded = true;
              this.fadeIn();
              clearInterval(loadingInterval);
              setInterval(this.draw,60);

            }
            drops[i] = 0;
        }
        drops[i]++;
      }
    },
    changeFact(){
      if(current_dir == "right"){
        document.getElementById("Fact"+(factIndex+1)+"-left").classList.remove("visible");
        document.getElementById("Fact"+(factIndex+1)+"-left").classList.add("hidden-left");
        factIndex = (factIndex+1)%fact_amount;
        document.getElementById("Fact"+(factIndex+1)+"-right").classList.remove("hidden-right");
        document.getElementById("Fact"+(factIndex+1)+"-right").classList.add("visible");
        current_dir = "left";
      }
      else{
        document.getElementById("Fact"+(factIndex+1)+"-right").classList.remove("visible");
        document.getElementById("Fact"+(factIndex+1)+"-right").classList.add("hidden-right");
        factIndex = (factIndex+1)%fact_amount;
        document.getElementById("Fact"+(factIndex+1)+"-left").classList.remove("hidden-left");
        document.getElementById("Fact"+(factIndex+1)+"-left").classList.add("visible");
        current_dir = "right";
      }
      
    },
    fadeIn(){
      document.getElementById("Intro1").classList.remove("hidden-left");
      document.getElementById("Intro1").classList.add("visible");
      document.getElementById("Intro2").classList.remove("hidden-right");
      document.getElementById("Intro2").classList.add("visible");
      setTimeout(() =>{
        document.getElementById("Fact1-left").classList.remove("hidden");
        document.getElementById("Fact1-left").classList.add("visible");
        setInterval(this.changeFact,5000);
      }, 1500);
      
    }
  },
  
}
</script>

<style scoped>
#matrix_rain{
  overflow-x:hidden;
  position:relative;
}
#matrix_rain div{
  position:absolute;
  color:white;
  
}
#Intro1{
  top:34.5%;
  font-size: 3rem;
}
#Intro2{
  top:46%;
  font-size: 3rem;
}
.fact{
  top:43%;
  font-size: 1.5rem;
}
.hidden-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 1.5s ease-out !important;
}
.hidden-right {
  opacity: 0;
  transform: translateX(50px);
  transition: all 1.5s ease-out !important;
}
.hidden{
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: all 2s ease-out !important;
}


</style>

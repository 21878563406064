<template>
	<div id="about" class="section">
    <h1 class="text-content">{{this.Heading}}</h1>
		<div class="row">
				<div class="col-12 col-md-6 d-flex  justify-content-center justify-content-md-start align-items-center">
					<div class="card card-responsive mb-3">
						<div class="card-body m-0 p-0 d-flex flex-column">
							<img src="../assets/IMG_20231124_115540.jpg" class="card-img-top" alt="A cool pic"/>
						</div>
					</div>
				</div>
			<div class="col-12 col-md-6 d-flex px-0">
				<div id="about-text">
					<h3 class="col-12 d-flex px-0 text-content">{{ this.IntroTitle }}</h3>
					<div class="col-12 d-flex px-0 text-content">{{ this.Intro1 }}</div>
					<div class="col-12 d-flex px-0 text-content">{{ this.Intro2 }}</div>
					<div class="col-12 d-flex px-0 text-content">{{ this.Intro3 }}</div>
					<div class="col-12 d-flex px-0 text-content">{{ this.Intro4 }}</div>
				</div>
			</div>
			<div class="w-100 col-12 col-md-6 d-flex px-0 justify-content-end align-items-end text-content">
				<a href="/Resume.pdf" download> {{ this.MoreAbout }}</a>
			</div>
			

		</div>
		
  </div>  
</template>
<script>
import { mounted } from 'vue';
import { computed } from 'vue';
export default {
    name: 'About',
	computed:{
		Heading(){
			return this.$t("About");
		},
		IntroTitle(){
			return this.$t("IntroTitle");
		},
		Intro1(){
			return this.$t("IntroLine1");
		},
		Intro2(){
			return this.$t("IntroLine2");
		},
		Intro3(){
			return this.$t("IntroLine3");
		},
		Intro4(){
			return this.$t("IntroLine4");
		},
		MoreAbout(){
			return this.$t("MoreAbout");
		},

	}
}
</script>
<style scoped>
h1{
    color:rgba(0,0,128);
    text-align: left;
}
.card-responsive{
  border-radius: 4px;
	z-index: 10;
  background: #fff;
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}

.card-responsive:hover{
  transform: scale(1.05);
}
div{
	text-align: left;
	font-size: 20px;
}

h2{
	text-align: left;
}
a{
color:  #87CEEB!important;
  background-color:black !important;
  z-index: 10;
}
a:hover{
	color: black !important;
  background-color:#87CEEB!important;
}

</style>